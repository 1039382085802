module.exports = [{
  url: 'http://ymsjimg.deaso40.com/avatars/阿格斯6号.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/安妮斯朵拉.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/大魔法师.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/董事局.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/黑客.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/黑猫.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/计时人.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/禁婆.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/警犬.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/雷鸣御守.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/入梦的费南迪尔.jpg'
}, {
  url: 'http://ymsjimg.deaso40.com/avatars/新生血族.jpg'
}]
