

































































import { defineComponent } from '@vue/composition-api'
import { setLang } from '@/i18n'

export default defineComponent({
  name: 'Footer',
  setup () {
    return {
      setLang
    }
  }
})
