<template>
  <div>
    <b-modal class="modalbackpos" :active="isCardModalActive"
      :on-cancel="cancelHandler"
      :width="480"
      scroll="keep">
      <div class="modalpos">
        <div class="upcontents">
          <div class="titletext">购买宝石</div>
          <!-- <button
            type="button"
            class="delete"
            @click="cancelHandler"/> -->
          <button v-for="(item, index) in priceList" :key="index" @click="clickGem(index)"
            class="button selectgem" :class="(index % 2 == 0 ? 'gembuttonleft' : 'gembuttonright') + (selectedIndex == index ? ' selected' : '')">
            <img class="gemicon" src="http://ymsjimg.deaso40.com/shop_slices/宝石15.png">
            <span>{{item}}</span>
          </button>
        </div>

        <div class="pricetext">总计需要花费{{getHooPrice()}} HOO</div>
        <button class="button is-dark enterbuy" @click="buyGem()">
          <span>确认购买</span>
        </button>

      </div>
    </b-modal>
  </div>
</template>

<script>
import nft_abi from '@/contracts/NFT_abi.json'

export default {
  data () {
    return {
      isCardModalActive: false,
      priceList: [100, 200, 500, 1000, 2000, 5000, 10000, 50000],
      selectedIndex: 0,
    }
  },
  computed: {
    web3 () {
      return this.$store.state.web3
    },
    contract () {
      const contract_in = this.web3.web3Instance().eth.contract(nft_abi)
      return contract_in.at(this.Global.contract_address)
    },
  },
  props: ['buymodalactive'],
  watch: {
    buymodalactive: function (val) {
      this.isCardModalActive = val;
    }
  },
  methods: {
    cancelHandler () {
      this.isCardModalActive = false
      this.$emit('update:buymodalactive', this.isCardModalActive)
    },
    getHooPrice () {
      return this.priceList[this.selectedIndex] * 0.01;
    },
    clickGem (index) {
      this.selectedIndex = index;
    },
    async buyGem () {
      const times = this.priceList[this.selectedIndex];
      if (!this.$store.state.web3.isInjected) {
        this.$buefy.dialog.alert({
          title: this.$t('用户未登录'),
          message: this.$t('请先用 Metamask 钱包登录！'),
          confirmText: this.$t('确认')
        })
        return
      }
      this.web3.web3Instance().eth.defaultAccount = this.web3.web3Instance().eth.coinbase
      var that = this
      await new Promise(
        (resolve, reject) => {
          that.contract.buyGem(
            times,
            { value: times * 1e16 },
            function (error, result) {
              if (!error) {
                that.$buefy.dialog.alert({
                  title: that.$t('支付成功'),
                  message: that.$t('购买宝石成功'),
                  confirmText: that.$t('确认')
                })
                that.$store.dispatch('drawCards', times)
                resolve(result)
              } else {
                that.$buefy.dialog.alert({
                  title: that.$t('支付失败'),
                  message: that.$t('用户取消了订单。'),
                  type: 'is-danger',
                  confirmText: that.$t('确认')
                })
                console.log('failed')
                that.$buefy.dialog.error(that.$t('支付失败'))
                reject(error)
              }
            })
        }
      )
    },
  }
}
</script>

<style scoped>
.modalbackpos{
  margin-top: 80px;
}
.modalpos{
  height: 400px;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 16px;
}
.modalbackpos >>> .modal-close{
  top: 100px;
}
.titletext{
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 16px;
}
.gemicon{
  width: 30px;
  height: 30px;
  margin-right: 4px;
  margin-left: 70px;
}
.selectgem{
  width: 220px;
  height: 40px;
  margin-bottom: 8px;
  color: #815004;
  font-size: 14px;
  font-weight: bold;
  justify-content: left;
  padding: 0px;
  border-color: #E4C46D;
}
.gembuttonleft{
  background-color: #ffffff;
}
.gembuttonright{
  margin-left: 8px;
  background-color: #ffffff;
}
.selected{
  background-color: #E4C46D;
}
.upcontents{
  /* 作用为把下面的组件挤下去 */
  height: calc(100% - 74px);
}
.pricetext{
  text-align: center;
  font-size: 14px;
  color: #B2B2B2;
  margin-bottom: 8px;
}
.enterbuy{
  margin: 0 auto;
  width: 240px;
  height: 40px;
}
</style>
