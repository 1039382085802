import Vue from 'vue'
import App from './App.vue'

import router from './router/index'
import { store } from './store/'

// @ts-ignore
import Bulma from 'bulma'
import 'bulma/css/bulma.min.css'
import VueCompositionAPI from '@vue/composition-api'

// @ts-ignore
import Buefy, { Dialog } from 'buefy'
import 'buefy/dist/buefy.css'

import vuescroll from 'vuescroll'

import { InfiniteScroll } from 'element-ui'

import VueClipboard from 'vue-clipboard2'
import { i18n } from '@/i18n'
Vue.use(VueCompositionAPI)
Vue.use(Bulma)
Vue.use(Buefy)
Vue.use(Dialog)
Vue.use(vuescroll)
Vue.use(InfiniteScroll)
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.Global = {
  contract_address: '0xd8f562d929E82054eFc39e7302Cb37ea22aab375',
  erc20_address: '0x9613d2D1Ba76dBf1D876FE62471A57BBC2E50e84',
  erc1155_address: '0xc6a87283443b5104EaBbd9924bdAb9F90eD40A45',
  endPoint: 'https://http-mainnet.hoosmartchain.com'
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default Vue
