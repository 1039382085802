module.exports = [
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_1_通灵mty.png',
    id: 1,
    type: 1,
    childType: [
      6
    ],
    cost: 1,
    factions: 0,
    loyal: 1,
    name: '通灵猫头鹰',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 0,
    cardNumber: 1,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      32
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/1.png',
    rarity: 0,
    ringCenterPos: '155,192',
    ringRadius: 137,
    bgStory: '洞察隐身潜伏的阴影杀手、钻过滴水不露的守卫结界，这些传统的法师伙伴具备了密探的一切优点: 仔细、谨慎和迅速。通灵猫头鹰以宠物身份服侍帷幕守望的法师们长达数个世纪，牠们经常穿梭在午夜的街道、传递着关键的信息。在电子邮箱与社交账号安保堪忧的现代，这些猛禽或许比任何先进的通讯手段更为可靠。',
    series: 1,
    seriesID: '1',
    skillInfo: '不能被装备结附：子类别为"装备"的附属\n，无法结附于该角色',
    modelId: 1,
    arrid: 0
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_2_巨石阵看护人.png',
    id: 2,
    type: 1,
    childType: [
      3
    ],
    cost: 2,
    factions: 0,
    loyal: 1,
    name: '巨石阵看护人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 4,
    cardNumber: 2,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      84
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/2.png',
    rarity: 0,
    ringCenterPos: '237,190',
    ringRadius: 134,
    bgStory: '尽管石阵已有数千年未曾开启，此间祭司却从未擅离职守。',
    series: 1,
    seriesID: '2',
    skillInfo: '现身：战场上的角色卡从"暗藏者"翻面，\n成为"角色"\n\n虚弱：无法参与调查、战斗、势力这三种对抗\n，在下一回合的准备阶段自动恢复"常态"',
    modelId: 2,
    arrid: 1
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_3_帷幕护卫.png',
    id: 3,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 0,
    loyal: 1,
    name: '帷幕护卫',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 0,
    cardNumber: 3,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      85
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/3.png',
    rarity: 0,
    ringCenterPos: '200,192',
    ringRadius: 136,
    bgStory: '永恒的帷幕必须得以守护，诸般隐秘事物应各安其位，依律而行。俗世的法规无法制约超凡的法师和异族，名为帷幕守望者的秘社便应运而生，为世界的隐秘一面建立秩序，并誓言守护凡人不受超自然力量的伤害。 \n',
    series: 1,
    seriesID: '3',
    skillInfo: '虚弱：无法参与调查、战斗、势力这三种对抗\n，在下一回合的准备阶段自动恢复"常态"',
    modelId: 3,
    arrid: 2
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_4_力场法师.png',
    id: 4,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 4,
    factions: 0,
    loyal: 2,
    name: '力场法师',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 1,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 0,
    cardNumber: 4,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      86
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/4.png',
    rarity: 2,
    ringCenterPos: '243,194',
    ringRadius: 134,
    bgStory: '过去，他们型塑结界阻止魔神的入侵；现在，他们封闭街道避开游民的滋扰，这群传承了古老技艺的心灵大师擅长聚焦意志隔绝魔法与驱逐恶灵。尽管世人早已遗忘先贤的牺牲，他们却未曾抛弃自己的职责--这些坚毅不拔的战斗法师是帷幕守望的中坚份子，负责看守所有已知的次元节点、正面对抗闯进凡尘的异界实体。\n$“他们虽然厌恶魔鬼、但也同样讨厌天使，因为两者系出同源，都不属于这块净土。”\n--麦可. 金 街头艺人\n',
    series: 1,
    seriesID: '4',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 4,
    arrid: 3
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_5_裂解术.png',
    id: 5,
    type: 2,
    childType: [
      8
    ],
    cost: 2,
    factions: 0,
    loyal: 1,
    name: '解构术',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 5,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      87
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/5.png',
    rarity: 0,
    ringCenterPos: '235,196',
    ringRadius: 143,
    bgStory: '在帷幕守望者的眼里，万事万物皆由基本的物质以复杂的规律所组成，诅咒、变形、读心和屏障都只是魔法不同的表现形式--凭借着适当的材料、姿势、咒语和媒介，他们得以引导能量为己所用。相对的，帷幕守望者也能透过剥离魔法的要素或连结进行反魔法--这道法术允许施法者解除目标身上某个特定的异常状态。\n$“假如每个法术都是一种打绳结的方法，那么你只要找到它的绳头并将其抽出，这道法术就会在转瞬间彻底崩解”\n--柏瑞克. 巴金斯 太阳马戏团的首席法师\n',
    series: 1,
    seriesID: '5',
    skillInfo: '',
    modelId: 5,
    arrid: 4
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_6_常春藤派学者.png',
    id: 6,
    type: 1,
    childType: [
      3
    ],
    cost: 4,
    factions: 0,
    loyal: 2,
    name: '常春藤派学者',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 2,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 6,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      88
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/6.png',
    rarity: 1,
    ringCenterPos: '203,198',
    ringRadius: 137,
    bgStory: '某些法师长年在高等院校指教，借此利用校内的丰富馆藏资源，以完成其不为人知的研究课题。 \n',
    series: 1,
    seriesID: '6',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 6,
    arrid: 5
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_7_逆转异界之门.png',
    id: 7,
    type: 2,
    childType: [
      8
    ],
    cost: 2,
    factions: 0,
    loyal: 1,
    name: '逆转异界之门',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 7,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      89
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/7.png',
    rarity: 0,
    ringCenterPos: '186,196',
    ringRadius: 137,
    bgStory: '帷幕守望者在世界各地设置监控处，密切监视空间紊乱现象，并派遣专人对抗来自裂缝的怪物。',
    series: 1,
    seriesID: '7',
    skillInfo: '',
    modelId: 7,
    arrid: 6
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_8_灵能激发.png',
    id: 8,
    type: 2,
    childType: [
      8
    ],
    cost: 2,
    factions: 0,
    loyal: 1,
    name: '灵能激发',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 8,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      90
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/8.png',
    rarity: 0,
    ringCenterPos: '198,199',
    ringRadius: 136,
    bgStory: '这个被帷幕守望者视为”头脑风暴”的仪式是他们用以探索奥秘的方式，藉由钻进自己的心灵深处，施法者得以进一步掌控自己的天赋，藉以达到更高的层次。这个仪式允许施术者在短暂的瞬间瞥见世界的真相--如此浩瀚而复杂的恐怖景象足以让任何凡人发疯，然而它也将向幸存者揭露让能量为己所用的宝贵知识。\n$“我曾经看过冷漠的古老幽灵，狡猾的时空窃贼、以及......恐怖的噩梦化身!”\n--艾米莉. 沙凡娜 纽约通灵人协会的资深会员',
    series: 1,
    seriesID: '8',
    skillInfo: '',
    modelId: 8,
    arrid: 7
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_10_青手，费南迪尔.png',
    id: 10,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 5,
    factions: 0,
    loyal: 3,
    name: '费南迪尔',
    childName: '青手',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 2,
    blackPowerNum: 2,
    def: 1,
    magicAreaID: 0,
    cardNumber: 10,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      92
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/10.png',
    rarity: 3,
    ringCenterPos: '227,195',
    ringRadius: 142,
    bgStory: '在洛杉矶的恐怖事件中，费南迪尔失去了双手和一切情感。但现在敌人们宁可面对当初尚未受伤的他。 \n',
    series: 1,
    seriesID: '10',
    skillInfo: '',
    modelId: 10,
    arrid: 8
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_11_德莫斯提尼·，大魔法师.png',
    id: 11,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 6,
    factions: 0,
    loyal: 4,
    name: '德莫斯提尼',
    childName: '大魔法师',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 2,
    blackPowerNum: 2,
    def: 2,
    magicAreaID: 0,
    cardNumber: 11,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      93,
      346
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/11.png',
    rarity: 4,
    ringCenterPos: '192,198',
    ringRadius: 137,
    bgStory: '德莫斯提尼大师精通空间和异界学识，而其在超凡者间的冥王更胜其魔法造诣。 \n',
    series: 1,
    seriesID: '11',
    skillInfo: '现身：战场上的角色卡从"暗藏者"翻面，\n成为"角色"',
    modelId: 11,
    arrid: 9
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_13_公路骑士.png',
    id: 13,
    type: 1,
    childType: [
      3
    ],
    cost: 2,
    factions: 1,
    loyal: 2,
    name: '公路骑士',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 2,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 13,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/13.png',
    rarity: 0,
    ringCenterPos: '249,196',
    ringRadius: 130,
    bgStory: '自妻女丧失之后，博纳·格兰特发誓要找到那怪物向其复仇。 \n',
    series: 1,
    seriesID: '13',
    skillInfo: '',
    modelId: 13,
    arrid: 10
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_14_驱魔人.png',
    id: 14,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 1,
    loyal: 1,
    name: '驱魔人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 1,
    cardNumber: 14,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      96
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/14.png',
    rarity: 0,
    ringCenterPos: '239,180',
    ringRadius: 127,
    bgStory: '有时候，祈祷和忏悔仍不足以让人们远离恶魔，因此驱魔人这项职业应运而生。这个由退休神父与私家侦探组成的行业拥有悠久的历史，一个合格的驱魔人通常会在通过教廷的严格训练后获得证书、接着选择为圣座服务或自行开业。这些专业人士誓言为天主奉献终生，为了对抗恶魔，他们抱着牺牲自己的觉悟投入工作。\n$"尽管驱魔人没有枪枝或炸药，但他们毫无疑问是一个宗教狂热的圣战组织!"\n--班森. 马蒂斯 伦敦大学凯弗学院的访问学者',
    series: 1,
    seriesID: '14',
    skillInfo: '牺牲：将自身送入拥有者的墓地，玩家只\n能选择并牺牲自己操控的卡牌',
    modelId: 14,
    arrid: 11
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_15_执行部精锐.png',
    id: 15,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 1,
    loyal: 2,
    name: '执行部精锐',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 3,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 15,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/15.png',
    rarity: 1,
    ringCenterPos: '188,198',
    ringRadius: 137,
    bgStory: '执行部是S.P.T.的直属作战精英，仅在最关键的行动中投入使用。 \n',
    series: 1,
    seriesID: '15',
    skillInfo: '',
    modelId: 15,
    arrid: 12
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_16_除咒师.png',
    id: 16,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 1,
    loyal: 1,
    name: '除咒师',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 0,
    cardNumber: 16,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      98
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/16.png',
    rarity: 0,
    ringCenterPos: '172,198',
    ringRadius: 145,
    bgStory: '“咱们得到希达夫人的宅邸一趟，希望她有能力解除这个恶咒。”——哈克·亚当斯 \n',
    series: 1,
    seriesID: '16',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 16,
    arrid: 13
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_17_弥尔顿的镇魔枪.png',
    id: 17,
    type: 4,
    childType: [
      1
    ],
    cost: 1,
    factions: 1,
    loyal: 2,
    name: '弥尔顿的镇魔枪',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 1,
    cardNumber: 17,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      99,
      35
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/17.png',
    rarity: 2,
    ringCenterPos: '238,194',
    ringRadius: 142,
    bgStory: '它的枪管由卢森堡的铁匠所锻造；枪身镌刻着苏美的楔形文字；握把为圣墓生长的树木制成：子弹以主教的银十字架镕铸；皮套饰以切割过的巨龙鳞片。这把传奇性的手枪属于范. 弥尔顿所有，其附着的祝福或许与屠戮的怪物同样众多，它被设计于摧毁异界生物--无论天使抑或魔鬼均对这件强大的魔法武器深感畏惧。\n$“这柄历史悠久的手枪属于十九世纪的卢森堡工艺，它曾为范. 弥尔顿爵士服务--直至这位当代最杰出的赏金猎人彻底消失在浓雾弥漫的伦敦街头。”\n--苏富比 工艺精品部门',
    series: 1,
    seriesID: '17',
    skillInfo: '虚弱：无法参与调查、战斗、势力这三种对抗\n，在下一回合的准备阶段自动恢复"常态"',
    modelId: 17,
    arrid: 14
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_18_魔刃传人.png',
    id: 18,
    type: 1,
    childType: [
      3
    ],
    cost: 4,
    factions: 1,
    loyal: 2,
    name: '魔刃传人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 0,
    cardNumber: 18,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      100
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/18.png',
    rarity: 1,
    ringCenterPos: '208,193',
    ringRadius: 136,
    bgStory: '魔刃找到了他的继承者，而异种们遭遇了新的死神。 ',
    series: 1,
    seriesID: '18',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 18,
    arrid: 15
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_19_引路仙子.png',
    id: 19,
    type: 1,
    childType: [
      22
    ],
    cost: 4,
    factions: 1,
    loyal: 2,
    name: '引路仙子',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 2,
    def: 1,
    magicAreaID: 2,
    cardNumber: 19,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      101
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/19.png',
    rarity: 1,
    ringCenterPos: '205,199',
    ringRadius: 137,
    bgStory: '帕克（唱）：奔到这边来，奔过那边去；我要领他们，奔来又奔去。林间和镇上，无人不怕我；我要领他们，走尽林中路！——威廉·莎士比亚，《仲夏夜之梦》 \n',
    series: 1,
    seriesID: '19',
    skillInfo: '持续：只要卡牌在场上，其持续能力就一\n直生效',
    modelId: 19,
    arrid: 16
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_20_隐蔽的飞刀.png',
    id: 20,
    type: 4,
    childType: [
      1
    ],
    cost: 2,
    factions: 1,
    loyal: 0,
    name: '隐蔽的飞刀',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 20,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      102,
      22
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/20.png',
    rarity: 0,
    ringCenterPos: '212,195',
    ringRadius: 137,
    bgStory: '第一刀只是警告，第二刀将直取妄动者性命。 \n',
    series: 1,
    seriesID: '20',
    skillInfo: '牺牲：将自身送入拥有者的墓地，玩家只\n能选择并牺牲自己操控的卡牌',
    modelId: 20,
    arrid: 17
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_21_狭路相逢.png',
    id: 21,
    type: 2,
    childType: [
      24
    ],
    cost: 2,
    factions: 1,
    loyal: 2,
    name: '狭路相逢',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 21,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      103
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/21.png',
    rarity: 1,
    ringCenterPos: '195,201',
    ringRadius: 137,
    bgStory: '“今晚该让一切有个了结！”——博纳·格兰特 ',
    series: 1,
    seriesID: '21',
    skillInfo: '',
    modelId: 21,
    arrid: 18
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_22_范·弥尔顿，.png',
    id: 22,
    type: 1,
    childType: [
      3
    ],
    cost: 6,
    factions: 1,
    loyal: 3,
    name: '范·弥尔顿',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 4,
    blackPowerNum: 0,
    def: 2,
    magicAreaID: 5,
    cardNumber: 22,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      104
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/22.png',
    rarity: 3,
    ringCenterPos: '187,196',
    ringRadius: 143,
    bgStory: '尼尔顿先生的威名在各族群落之间流传，其所到之处，异种闻风丧胆。 \n',
    series: 1,
    seriesID: '22',
    skillInfo: '持续：只要卡牌在场上，其持续能力就一\n直生效',
    modelId: 22,
    arrid: 19
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_23_S.P.T董事局，.png',
    id: 23,
    type: 1,
    childType: [
      3
    ],
    cost: 8,
    factions: 1,
    loyal: 4,
    name: 'S.P.T.董事局',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 2,
    blackBattleNum: 0,
    blackPowerNum: 3,
    def: 3,
    magicAreaID: 5,
    cardNumber: 23,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      106
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/23.png',
    rarity: 4,
    ringCenterPos: '196,202',
    ringRadius: 137,
    bgStory: '',
    series: 1,
    seriesID: '23',
    skillInfo: '持续：只要卡牌在场上，其持续能力就一\n直生效',
    modelId: 23,
    arrid: 20
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_25_鲜血奴仆.png',
    id: 25,
    type: 1,
    childType: [
      27
    ],
    cost: 1,
    factions: 2,
    loyal: 1,
    name: '鲜血奴仆',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 4,
    cardNumber: 25,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      110
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/25.png',
    rarity: 0,
    ringCenterPos: '204,195',
    ringRadius: 130,
    bgStory: '血族们很喜欢驱使这些以邪法扭曲过心智的仆人，而在关键时刻他们也不吝于榨干它们。。 \n',
    series: 1,
    seriesID: '25',
    skillInfo: '牺牲：将自身送入拥有者的墓地，玩家只\n能选择并牺牲自己操控的卡牌',
    modelId: 25,
    arrid: 21
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_26_新生血族.png',
    id: 26,
    type: 1,
    childType: [
      27
    ],
    cost: 2,
    factions: 2,
    loyal: 2,
    name: '新生血族',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 2,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 4,
    cardNumber: 26,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/26.png',
    rarity: 0,
    ringCenterPos: '201,190',
    ringRadius: 136,
    bgStory: '“嘿，女士！你没事吧？需要我来帮你……噢不，这是什——呃啊啊啊啊啊！……”  \n',
    series: 1,
    seriesID: '26',
    skillInfo: '',
    modelId: 26,
    arrid: 22
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_27_巨型蝙蝠.png',
    id: 27,
    type: 1,
    childType: [
      29
    ],
    cost: 3,
    factions: 2,
    loyal: 2,
    name: '巨型蝙蝠',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 2,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 27,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      32
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/27.png',
    rarity: 0,
    ringCenterPos: '186,192',
    ringRadius: 136,
    bgStory: '精于变化之道的血族，能够变幻身形，以别种相貌于人前。 \n',
    series: 1,
    seriesID: '27',
    skillInfo: '不能被装备结附：子类别为“装备”的附\n属，无法结附于该角色',
    modelId: 27,
    arrid: 23
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_28_唤灵师.png',
    id: 28,
    type: 1,
    childType: [
      27
    ],
    cost: 3,
    factions: 2,
    loyal: 2,
    name: '唤灵师',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 3,
    cardNumber: 28,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      113
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/28.png',
    rarity: 1,
    ringCenterPos: '229,191',
    ringRadius: 135,
    bgStory: '行于骸骨之道的血族，能驭使亡灵，与死者的魂魄交谈。 \n',
    series: 1,
    seriesID: '28',
    skillInfo: '',
    modelId: 28,
    arrid: 24
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_29_初拥.png',
    id: 29,
    type: 4,
    childType: [
      15
    ],
    cost: 5,
    factions: 2,
    loyal: 2,
    name: '初拥',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 4,
    cardNumber: 29,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      114,
      34
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/29.png',
    rarity: 0,
    ringCenterPos: '194,193',
    ringRadius: 134,
    bgStory: '对血族而言，初拥代表了宣告猎物的归属权，对被初拥而言，则意味着放弃了阳光下的世界。 \n',
    series: 1,
    seriesID: '29',
    skillInfo: '',
    modelId: 29,
    arrid: 25
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_30_血族长老.png',
    id: 30,
    type: 1,
    childType: [
      27
    ],
    cost: 5,
    factions: 2,
    loyal: 2,
    name: '血族长老',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 2,
    def: 3,
    magicAreaID: 3,
    cardNumber: 30,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      115
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/30.png',
    rarity: 2,
    ringCenterPos: '182,193',
    ringRadius: 137,
    bgStory: '每位新生的血族，都受其所在区域的长老制约。 \n',
    series: 1,
    seriesID: '30',
    skillInfo: '',
    modelId: 30,
    arrid: 26
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_32_传媒魔咒.png',
    id: 32,
    type: 4,
    childType: [
      54
    ],
    cost: 3,
    factions: 2,
    loyal: 1,
    name: '传媒魔咒',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 32,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 1,
    effectID: [
      118,
      31
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/32.png',
    rarity: 0,
    ringCenterPos: '203,176',
    ringRadius: 127,
    bgStory: '关于吸血鬼透过凝视控制人类的传说早已被现代儿童抛在脑后，他们紧抓手里的遥控器，宣称那些黑暗生物只不过是仅存在于小说里的虚构角色。然而，现代的吸血鬼早已透过控股和收购垄断了媒体市场，将带有菁英主义与种族主义的内容隐藏在铺天盖地的节目与广告里，企图分化人们的意志、引导新世界秩序的降临。\n$“古老的富豪家族透过媒体控制舆论，日益侵蚀我们尚未沦陷的精神世界。”\n--亨利. 豪斯 著名的阴谋论人士',
    series: 1,
    seriesID: '32',
    skillInfo: '准备阶段：每回合最开始的阶段，在先手\n行动阶段之前\n\n强制：该能力在符合条件的时机必须发动',
    modelId: 32,
    arrid: 27
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_34_克里斯蒂娜·，爱弗罗德.png',
    id: 34,
    type: 1,
    childType: [
      27
    ],
    cost: 6,
    factions: 2,
    loyal: 3,
    name: '克里斯蒂娜',
    childName: '爱佛罗德',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 2,
    blackPowerNum: 2,
    def: 3,
    magicAreaID: 4,
    cardNumber: 34,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      121
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/34.png',
    rarity: 3,
    ringCenterPos: '242,201',
    ringRadius: 136,
    bgStory: '“你带来的故事书确实很有趣。”克里斯蒂娜露出一个细微的笑容。“现在我们在谈谈正事吧。” \n',
    series: 1,
    seriesID: '34',
    skillInfo: '',
    modelId: 34,
    arrid: 28
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_35_列恩，·爱弗罗德.png',
    id: 35,
    type: 1,
    childType: [
      27
    ],
    cost: 8,
    factions: 2,
    loyal: 3,
    name: '列恩',
    childName: '爱佛罗德',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 2,
    blackPowerNum: 3,
    def: 4,
    magicAreaID: 3,
    cardNumber: 35,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      122
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/35.png',
    rarity: 4,
    ringCenterPos: '209,187',
    ringRadius: 136,
    bgStory: '爱弗罗德家族本是克罗地亚一支小贵族。在其不死主人的带领下，此家族日益强盛。 \n',
    series: 1,
    seriesID: '35',
    skillInfo: '',
    modelId: 35,
    arrid: 29
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_49_地区警员.png',
    id: 49,
    type: 1,
    childType: [
      3
    ],
    cost: 2,
    factions: 4,
    loyal: 1,
    name: '地区警员',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 49,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      176
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/49.png',
    rarity: 0,
    ringCenterPos: '236,195',
    ringRadius: 137,
    bgStory: '“总台，看到罪案调查科找的人了。我跟上去看看，保持联络……”——波克曼警官的遗言  \n',
    series: 1,
    seriesID: '49',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动\n\n虚弱：无法参与调查、战斗、势力这三种对抗\n，在下一回合的准备阶段自动恢复"常态"',
    modelId: 49,
    arrid: 30
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_50_监控员.png',
    id: 50,
    type: 1,
    childType: [
      3
    ],
    cost: 2,
    factions: 4,
    loyal: 1,
    name: '监控员',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 50,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      177
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/50.png',
    rarity: 0,
    ringCenterPos: '167,206',
    ringRadius: 130,
    bgStory: '“我们部门对所有‘特殊人士’保持长年监控。尽管如此，是的，突发事故依然无法避免。”——西海岸监控中心的库迪尔  \n',
    series: 1,
    seriesID: '50',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 50,
    arrid: 31
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_51_坚毅的刑警.png',
    id: 51,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 4,
    loyal: 1,
    name: '坚毅的刑警',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 1,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 51,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      179
    ],
    limitNum: 3,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/51.png',
    rarity: 1,
    ringCenterPos: '188,194',
    ringRadius: 141,
    bgStory: '尽管人质安然无恙，但看着地上这具非人的尸体，佩恩警官还是为此次报告该怎么写而感到头疼。 \n',
    series: 1,
    seriesID: '51',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 51,
    arrid: 32
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_52_安全保卫部门.png',
    id: 52,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 4,
    loyal: 0,
    name: '安全保卫部门',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 5,
    cardNumber: 52,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      180
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/52.png',
    rarity: 2,
    ringCenterPos: '194,194',
    ringRadius: 143,
    bgStory: '无论在事务繁忙的行政部门、处理机密的情报机构，抑或要员聚集的外交使馆，总少不了一支全副武装的保安部队。经验丰富的老手总是随身配备防弹背心和重型枪械，因为他们了解下一秒可能发生任何状况。这些专家同样擅长应付可疑的访客与无礼的记者，他们会透过粗暴的手段保证重要人士不受任何形式的打扰。\n$"当海豹部队退役队员转往黑水服务时，他卸下的只有勋章，没有拳头。"\n--尼克松. 纽曼 纽约时报 军事专栏作者',
    series: 1,
    seriesID: '52',
    skillInfo: '持续：只要卡牌在场上，其持续能力就一\n直生效',
    modelId: 52,
    arrid: 33
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_53_神秘学顾问.png',
    id: 53,
    type: 1,
    childType: [
      3
    ],
    cost: 3,
    factions: 4,
    loyal: 1,
    name: '神秘学顾问',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 0,
    cardNumber: 53,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      181
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/53.png',
    rarity: 1,
    ringCenterPos: '201,200',
    ringRadius: 137,
    bgStory: '繁琐的程序和腐败的官僚导致许多颇有天赋的人士不愿意为国效劳，然而，仍有少数人看到了拥有豁免权和数据库的重大价值--情报机构开出优渥的合同招募这些专家，他们对黑暗生物的弱点暸若指掌，能将正规的武装特警训练成恶魔猎人。这些人士通常带有奇特的刺青或法器，就连傲慢的将军们也对他们毕恭毕敬。\n$"每当我解答了一个谜题，就会衍生出更多的谜题。"\n--亚当. 泰勒 英国共济会会员',
    series: 1,
    seriesID: '53',
    skillInfo: '',
    modelId: 53,
    arrid: 34
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_54_危机处理特派小组.png',
    id: 54,
    type: 1,
    childType: [
      3
    ],
    cost: 5,
    factions: 4,
    loyal: 2,
    name: '危机处理特派小组',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 2,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 5,
    cardNumber: 54,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      182
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/54.png',
    rarity: 1,
    ringCenterPos: '198,194',
    ringRadius: 137,
    bgStory: '某些时刻，政府不得不采取非正规的手段达成目的，也许是为了抹除一只失控的异界生物或某个难缠的邪教组织。无论如何，不管目标藏匿在人群、堡垒或巷弄，这些训练有素的人士都能让对方迅速退场--这支多才多艺的小组可以在短时间里完成入侵系统、破译密码与安装炸药的一系列行动，确保锁定的目标从此沉默。\n$“只有最为难缠的军火掮客和最为杰出的黑帽黑客有这个荣幸和他们吃顿晚餐”\n--珍妮弗. 肯尼迪 国家安全顾问特别助理',
    series: 1,
    seriesID: '54',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 54,
    arrid: 35
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_55_档案调查.png',
    id: 55,
    type: 2,
    childType: [
      24
    ],
    cost: 1,
    factions: 4,
    loyal: 1,
    name: '调查档案',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 55,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      183
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/55.png',
    rarity: 0,
    ringCenterPos: '173,209',
    ringRadius: 137,
    bgStory: '一直以来，橱柜里的证书、手机里的记录和口袋内的发票，都让人们的生活在政府面前无所遁形。作为情报机构的调查员，这些人士有权索取公民的个人资料，无论对方是否同意。若有必要，任何人从出生到现在的所有数据就会在数小时内被摊开在调查员的面前--他们或许会比你还了解你昨晚在餐馆里花了多少吃饭。\n$“也许你能让自己从这个社会上彻底消失，但你的亲朋好友呢?”\n--伦斯斐. 渥克 刚出狱的资深黑客',
    series: 1,
    seriesID: '55',
    skillInfo: '',
    modelId: 55,
    arrid: 36
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_56_警车追捕.png',
    id: 56,
    type: 2,
    childType: [
      24
    ],
    cost: 1,
    factions: 4,
    loyal: 1,
    name: '警车追捕',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 56,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      184
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/56.png',
    rarity: 0,
    ringCenterPos: '193,197',
    ringRadius: 142,
    bgStory: '“疑犯极度危险！各单位退后，交给专业人士处理！”',
    series: 1,
    seriesID: '56',
    skillInfo: '',
    modelId: 56,
    arrid: 37
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_57_戒严.png',
    id: 57,
    type: 4,
    childType: [
      44
    ],
    cost: 2,
    factions: 4,
    loyal: 1,
    name: '戒严',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 57,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 1,
    effectID: [
      31,
      185,
      281
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/57.png',
    rarity: 0,
    ringCenterPos: '194,197',
    ringRadius: 137,
    bgStory: '梦魇事件爆发4小时后，政府特别部门介入，紧急封锁洛杉矶市区，然而此时事态已然失去了控制。\n',
    series: 1,
    seriesID: '57',
    skillInfo: '准备步骤：每回合最开始的阶段，在先手\n行动阶段之前\n\n强制：该能力在符合条件的时机必须发动',
    modelId: 57,
    arrid: 38
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_58_审问嫌疑人.png',
    id: 58,
    type: 2,
    childType: [
      24
    ],
    cost: 1,
    factions: 4,
    loyal: 1,
    name: '审问嫌疑人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 58,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      186
    ],
    limitNum: 2,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/58.png',
    rarity: 0,
    ringCenterPos: '197,202',
    ringRadius: 145,
    bgStory: '“嘿，这又是好警察坏警察的把戏吗？”\n',
    series: 1,
    seriesID: '58',
    skillInfo: '',
    modelId: 58,
    arrid: 39
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_59_封魔瓶，瑟琳娜.png',
    id: 59,
    type: 1,
    childType: [
      3
    ],
    cost: 4,
    factions: 4,
    loyal: 3,
    name: '封魔瓶瑟琳娜',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 0,
    cardNumber: 59,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      187
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/59.png',
    rarity: 3,
    ringCenterPos: '233,195',
    ringRadius: 143,
    bgStory: '在童年时，瑟琳娜就已展现出她的独特天赋--这个小女孩仅约五岁时就已理解了行星运转的规律；于十岁时描绘出了艰涩复杂的时空结构；在十三岁前撰写了一本阐释维度的手札。毫无疑问，瑟琳娜对空间的认知超越了人类的已知范畴，但她却深陷于高智商带来的痛苦--自闭症让这个女孩长年待在房间，从未踏出家门。\n瑟琳娜的非凡天赋不只吸引了同类，天使、幽魂与梦魔等灵体同样垂涎于其强大潜能--她拥有着透彻的思绪和宏伟的构想，宛如脑海里容纳着一个微型宇宙。然而，瑟琳娜迄今仍保有她的意志，因为所有尝试闯进其脑海的生物现在均受困于前者浩瀚无际的思绪里，日复一日地承受心智与肉体被强制剥离出来的强烈痛楚。\n$这个女孩的梦境世界如此璀璨壮丽而又何其宏伟浩瀚，宛如出自上帝手笔。”\n--苏珊. 华格纳 心理学家',
    series: 1,
    seriesID: '59',
    skillInfo: '进场：在角色卡"派遣"或"现身"之后发动',
    modelId: 59,
    arrid: 40
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/0_60_约翰·F·纳特里，.png',
    id: 60,
    type: 1,
    childType: [
      3
    ],
    cost: 6,
    factions: 4,
    loyal: 3,
    name: '约翰F纳特里',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 3,
    def: 1,
    magicAreaID: 5,
    cardNumber: 60,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      189
    ],
    limitNum: 1,
    isDebar: false,
    screenshotPath: 'asset/cardScreenshot/60.png',
    rarity: 4,
    ringCenterPos: '183,190',
    ringRadius: 137,
    bgStory: '“照我说的做。”  \n',
    series: 1,
    seriesID: '60',
    skillInfo: '',
    modelId: 60,
    arrid: 41
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/01联会禁音使.png',
    id: 107,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 3,
    factions: 0,
    loyal: 2,
    name: '联会禁音使',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 0,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      282
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 107,
    arrid: 42
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/02轮回秘仪.png',
    id: 108,
    type: 4,
    childType: [
      8,
      79
    ],
    cost: 3,
    factions: 0,
    loyal: 1,
    name: '轮回秘仪',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      283
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 108,
    arrid: 43
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/03力场束缚.png',
    id: 109,
    type: 4,
    childType: [
      8,
      9
    ],
    cost: 2,
    factions: 0,
    loyal: 1,
    name: '力场束缚',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      284
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 109,
    arrid: 44
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/04抹除现实.png',
    id: 110,
    type: 4,
    childType: [
      8,
      79
    ],
    cost: 4,
    factions: 0,
    loyal: 1,
    name: '抹除现实',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      285
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 110,
    arrid: 45
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/05天火的捷列娃.png',
    id: 111,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 5,
    factions: 0,
    loyal: 3,
    name: '天火的捷列娃',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 2,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 1,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      286
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 111,
    arrid: 46
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/06入梦的费南迪尔.png',
    id: 112,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 6,
    factions: 0,
    loyal: 3,
    name: '入梦的费南迪尔',
    childName: '',
    isGolden: true,
    whiteSpyNum: 1,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 2,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      287,
      288
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 112,
    arrid: 47
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/07圣水.png',
    id: 113,
    type: 4,
    childType: [
      1,
      80
    ],
    cost: 1,
    factions: 1,
    loyal: 1,
    name: '圣水',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 1,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 4,
    effectID: [
      289
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 113,
    arrid: 48
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/08银弹爆头.png',
    id: 114,
    type: 2,
    childType: [
      81
    ],
    cost: 3,
    factions: 1,
    loyal: 1,
    name: '银弹爆头',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      290
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 114,
    arrid: 49
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/09临时征召.png',
    id: 115,
    type: 2,
    childType: [
      24
    ],
    cost: 4,
    factions: 1,
    loyal: 2,
    name: '临时召唤',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      291
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 115,
    arrid: 50
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/10邪物克星.png',
    id: 116,
    type: 1,
    childType: [
      3,
      19
    ],
    cost: 4,
    factions: 1,
    loyal: 1,
    name: '邪物克星',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 2,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      49,
      292
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 116,
    arrid: 51
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/11伊丽莎白.png',
    id: 117,
    type: 1,
    childType: [
      3,
      19
    ],
    cost: 3,
    factions: 1,
    loyal: 1,
    name: '伊丽莎白',
    childName: '“知更鸟”',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      49,
      50,
      293
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 117,
    arrid: 52
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/12暴躁血仆.png',
    id: 118,
    type: 1,
    childType: [
      27,
      28
    ],
    cost: 2,
    factions: 2,
    loyal: 2,
    name: '暴躁血仆',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 1,
    whitePowerNum: 1,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 4,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      294
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 118,
    arrid: 53
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/13焚血之道.png',
    id: 119,
    type: 4,
    childType: [
      31,
      27
    ],
    cost: 1,
    factions: 2,
    loyal: 3,
    name: '焚血之道',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 4,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      295,
      29
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 119,
    arrid: 54
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/14鲜血.png',
    id: 120,
    type: 4,
    childType: [
      15
    ],
    cost: 0,
    factions: 2,
    loyal: 1,
    name: '鲜血',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 4,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      296,
      36
    ],
    limitNum: 0,
    isDebar: false,
    screenshotPath: 'null',
    rarity: 0,
    ringCenterPos: '200,600',
    ringRadius: 211,
    bgStory: '',
    series: 1,
    seriesID: '117',
    skillInfo: '持续：只要卡牌在场上，其持续能力就一\n直生效',
    modelId: 120,
    arrid: 55
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/15爱弗罗德庄园.png',
    id: 121,
    type: 4,
    childType: [
      82
    ],
    cost: 2,
    factions: 2,
    loyal: 1,
    name: '爱佛洛德庄园',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      29,
      297
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 121,
    arrid: 56
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/16法务部律师团.png',
    id: 122,
    type: 1,
    childType: [
      3,
      83
    ],
    cost: 2,
    factions: 2,
    loyal: 1,
    name: '法务部律师团',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      47,
      298
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 122,
    arrid: 57
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/17祭品.png',
    id: 123,
    type: 1,
    childType: [
      3
    ],
    cost: 1,
    factions: 3,
    loyal: 1,
    name: '祭品',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 1,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      299
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 123,
    arrid: 58
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/18计时人.png',
    id: 124,
    type: 1,
    childType: [
      3,
      4,
      35
    ],
    cost: 2,
    factions: 3,
    loyal: 2,
    name: '计时人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 1,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 3,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      300
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 124,
    arrid: 59
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/19熵力化身.png',
    id: 125,
    type: 1,
    childType: [
      10
    ],
    cost: 0,
    factions: 3,
    loyal: 0,
    name: '熵力化身',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 1,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      45,
      52,
      301
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 125,
    arrid: 60
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/20灵魂通道.png',
    id: 126,
    type: 2,
    childType: [
      8,
      84
    ],
    cost: 2,
    factions: 3,
    loyal: 1,
    name: '灵魂通道',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 4,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      302
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 126,
    arrid: 61
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/21臣服于命运.png',
    id: 127,
    type: 2,
    childType: [
      33
    ],
    cost: 1,
    factions: 3,
    loyal: 1,
    name: '臣服于命运',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 4,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      303
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 127,
    arrid: 62
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/22州议员.png',
    id: 128,
    type: 1,
    childType: [
      3,
      62
    ],
    cost: 3,
    factions: 4,
    loyal: 1,
    name: '州议员贝伦希恩斯',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      304,
      305
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 128,
    arrid: 63
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/23谨慎的办事员.png',
    id: 129,
    type: 1,
    childType: [
      3,
      85
    ],
    cost: 1,
    factions: 4,
    loyal: 1,
    name: '谨慎的办事员',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      47,
      49,
      306
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 129,
    arrid: 64
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/24防暴警察.png',
    id: 130,
    type: 1,
    childType: [
      3,
      40
    ],
    cost: 4,
    factions: 4,
    loyal: 2,
    name: '防暴警察',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 1,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      47,
      307
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 130,
    arrid: 65
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/25监控探头.png',
    id: 131,
    type: 4,
    childType: [
      86
    ],
    cost: 2,
    factions: 4,
    loyal: 2,
    name: '监控探头',
    childName: '',
    isGolden: false,
    whiteSpyNum: 1,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 1,
    effectID: [
      31,
      308
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 131,
    arrid: 66
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/26通缉令.png',
    id: 132,
    type: 4,
    childType: [
      44
    ],
    cost: 1,
    factions: 4,
    loyal: 2,
    name: '通缉令',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      29,
      309,
      310
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 132,
    arrid: 67
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/27定点清除行动.png',
    id: 133,
    type: 2,
    childType: [
      69
    ],
    cost: 4,
    factions: 4,
    loyal: 2,
    name: '定点清除行动',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      311
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 133,
    arrid: 68
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/28冥想者.png',
    id: 134,
    type: 1,
    childType: [
      3,
      45
    ],
    cost: 2,
    factions: 5,
    loyal: 1,
    name: '冥想者',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 0,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      312
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 134,
    arrid: 69
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/29安魂师.png',
    id: 135,
    type: 1,
    childType: [
      3,
      4
    ],
    cost: 2,
    factions: 5,
    loyal: 2,
    name: '阿塔科拉安魂师',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 3,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      313
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 135,
    arrid: 70
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/30阿瓦隆岛的迷雾.png',
    id: 136,
    type: 4,
    childType: [
      54
    ],
    cost: 2,
    factions: 5,
    loyal: 2,
    name: '阿瓦隆岛的迷雾',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 6,
    effectID: [
      314,
      315
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 136,
    arrid: 71
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/32面壁苦修.png',
    id: 137,
    type: 4,
    childType: [
      87
    ],
    cost: 1,
    factions: 5,
    loyal: 2,
    name: '面壁苦修',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 6,
    effectID: [
      29,
      316,
      317
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 137,
    arrid: 72
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/33慈悲.png',
    id: 138,
    type: 2,
    childType: [
      8,
      48
    ],
    cost: 4,
    factions: 5,
    loyal: 2,
    name: '慈悲',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 1,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      318
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 138,
    arrid: 73
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/34灵感.png',
    id: 139,
    type: 2,
    childType: [
      49
    ],
    cost: 1,
    factions: 5,
    loyal: 1,
    name: '灵感',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      319,
      320
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 139,
    arrid: 74
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/35白骨仆人.png',
    id: 140,
    type: 1,
    childType: [
      59,
      88,
      28
    ],
    cost: 0,
    factions: 6,
    loyal: 0,
    name: '白骨仆人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 3,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      321
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 140,
    arrid: 75
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/36圣甲虫的清理员.png',
    id: 141,
    type: 1,
    childType: [
      3,
      89
    ],
    cost: 1,
    factions: 6,
    loyal: 1,
    name: '圣甲虫的清理员',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      322
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 141,
    arrid: 76
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/37夜总会看门人.png',
    id: 142,
    type: 1,
    childType: [
      3,
      5
    ],
    cost: 2,
    factions: 6,
    loyal: 1,
    name: '夜总会看门人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      323
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 142,
    arrid: 77
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/38金特易卜拉欣.png',
    id: 143,
    type: 1,
    childType: [
      3,
      17
    ],
    cost: 3,
    factions: 6,
    loyal: 2,
    name: '金特易卜拉欣',
    childName: '',
    isGolden: true,
    whiteSpyNum: 2,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 2,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      324,
      325
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 143,
    arrid: 78
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/39方碑徽记.png',
    id: 144,
    type: 4,
    childType: [
      51
    ],
    cost: 2,
    factions: 6,
    loyal: 1,
    name: '方碑印记',
    childName: '',
    isGolden: true,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 6,
    effectID: [
      29,
      326,
      327
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 144,
    arrid: 79
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/40催眠术表演者.png',
    id: 145,
    type: 1,
    childType: [
      3,
      20
    ],
    cost: 2,
    factions: 7,
    loyal: 1,
    name: '催眠术表演者',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      328
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 145,
    arrid: 80
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/41启迪之梦.png',
    id: 146,
    type: 1,
    childType: [
      13
    ],
    cost: 3,
    factions: 7,
    loyal: 2,
    name: '启迪之梦',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 1,
    blackSpyNum: 1,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      329,
      45
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 146,
    arrid: 81
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/42幻华.png',
    id: 147,
    type: 4,
    childType: [
      13
    ],
    cost: 1,
    factions: 7,
    loyal: 2,
    name: '幻华',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 1,
    effectID: [
      31,
      49,
      330
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 147,
    arrid: 82
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/43具现化.png',
    id: 148,
    type: 4,
    childType: [
      54
    ],
    cost: 2,
    factions: 7,
    loyal: 1,
    name: '具现化',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 1,
    effectID: [
      31,
      331
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 148,
    arrid: 83
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/44随风入梦.png',
    id: 149,
    type: 2,
    childType: [
      8,
      48
    ],
    cost: 2,
    factions: 7,
    loyal: 2,
    name: '随风入梦',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 0,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      332
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 149,
    arrid: 84
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/45破颅而出.png',
    id: 150,
    type: 2,
    childType: [
      69,
      13
    ],
    cost: 1,
    factions: 7,
    loyal: 3,
    name: '破颅而出',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 2,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      333
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 150,
    arrid: 85
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/46无名尸体.png',
    id: 151,
    type: 1,
    childType: [
      3
    ],
    cost: 0,
    factions: 8,
    loyal: 0,
    name: '无名尸体',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 151,
    arrid: 86
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/47防弹战术背心.png',
    id: 152,
    type: 4,
    childType: [
      1,
      90
    ],
    cost: 1,
    factions: 8,
    loyal: 0,
    name: '防弹战术背心',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      21,
      334,
      335
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 152,
    arrid: 87
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/48街头演说家.png',
    id: 153,
    type: 1,
    childType: [
      3,
      62
    ],
    cost: 4,
    factions: 8,
    loyal: 0,
    name: '街头演说家',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 1,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      47,
      46,
      336
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 153,
    arrid: 88
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/49葬礼.png',
    id: 154,
    type: 2,
    childType: [
      33
    ],
    cost: 1,
    factions: 8,
    loyal: 0,
    name: '葬礼',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      337
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 154,
    arrid: 89
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/50电磁风暴.png',
    id: 155,
    type: 2,
    childType: [
      24,
      25
    ],
    cost: 3,
    factions: 8,
    loyal: 0,
    name: '电磁风暴',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 0,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      338
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 155,
    arrid: 90
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/51秘社的使节.png',
    id: 156,
    type: 1,
    childType: [
      3,
      62
    ],
    cost: 2,
    factions: 8,
    loyal: 0,
    name: '凯特琳女士',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      47,
      46,
      339
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 156,
    arrid: 91
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/52瘦长鬼影.png',
    id: 157,
    type: 1,
    childType: [
      91
    ],
    cost: 0,
    factions: 8,
    loyal: 0,
    name: '瘦长鬼影',
    childName: '',
    isGolden: false,
    whiteSpyNum: 1,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 2,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 3,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      340,
      74,
      341
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 157,
    arrid: 92
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/53裂口女.png',
    id: 158,
    type: 1,
    childType: [
      91
    ],
    cost: 0,
    factions: 8,
    loyal: 0,
    name: '裂口女',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 1,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 1,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 3,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [
      342,
      73
    ],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 158,
    arrid: 93
  },
  {
    url: 'http://ymsjimg.deaso40.com/cards/54无知路人.png',
    id: 159,
    type: 1,
    childType: [
      3
    ],
    cost: 0,
    factions: 8,
    loyal: 0,
    name: '无知路人',
    childName: '',
    isGolden: false,
    whiteSpyNum: 0,
    whiteBattleNum: 0,
    whitePowerNum: 0,
    blackSpyNum: 0,
    blackBattleNum: 0,
    blackPowerNum: 0,
    def: 1,
    magicAreaID: 5,
    cardNumber: 0,
    cardPainterID: 0,
    area_needWinPower: 0,
    area_score: 0,
    addType: 0,
    effectID: [],
    limitNum: 0,
    isDebar: true,
    screenshotPath: null,
    rarity: null,
    ringCenterPos: null,
    ringRadius: null,
    bgStory: null,
    series: null,
    seriesID: null,
    skillInfo: '',
    modelId: 159,
    arrid: 94
  }
]
