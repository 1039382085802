import { render, staticRenderFns } from "./CardDetail.vue?vue&type=template&id=6869e8c0&scoped=true&"
import script from "./CardDetail.vue?vue&type=script&lang=js&"
export * from "./CardDetail.vue?vue&type=script&lang=js&"
import style0 from "./CardDetail.vue?vue&type=style&index=0&id=6869e8c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6869e8c0",
  null
  
)

export default component.exports